<template>
  <form id="frm_action">
    <div class="p-formgrid product-form">
      <div class="p-col-12 p-lg-12 view-action">
        <div class="card-w-title">
          <Panel>
            <div class="p-formgrid">
              <div class="p-card-field p-grid">
                <div class="col-12 no-padding">
                  <h5>THÔNG TIN SẢN PHẨM</h5>
                </div>
                <div class="p-field position-relative no-padding p-lg-6 p-col-12">
                  <label class="p-col-fixed txt-left"
                    >Mã sản phẩm <span class="span-cus">*</span></label
                  >
                  <div class="p-col">
                    <InputText
                      v-model="code"
                      type="text"
                      id="code"
                      placeholder="Nhập"
                    />
                  </div>
                </div>

                <div class="p-field position-relative no-padding p-lg-6 d-lg-block d-none">
                  <label class="p-col-fixed txt-left"
                    >Mã nhóm vật tư hàng hóa
                    <span class="span-cus">*</span></label
                  >
                  <div class="p-col">
                    <InputText
                      v-model="material_code"
                      type="text"
                      id="material_code"
                      disabled="disabled"
                    />
                  </div>
                </div>

                <div class="p-field position-relative no-padding p-lg-6 p-col-12">
                  <label class="p-col-fixed txt-left"
                    >Tên sản phẩm <span class="span-cus">*</span></label
                  >
                  <div class="p-col">
                    <InputText
                      v-model="name"
                      type="text"
                      id="name"
                      maxlength="255"
                      placeholder="Nhập"
                    />
                  </div>
                </div>

                <div class="p-field position-relative no-padding p-lg-6 d-lg-block d-none">
                  <label class="p-col-fixed txt-left"
                    >Đơn vị tính <span class="span-cus">*</span></label
                  >
                  <div class="p-col">
                    <v-select :clearable="false"
                      title="Đơn vị tính"
                      id="a1"
                      :filterable="false"
                      placeholder="Chọn"
                      v-model="selUnit"
                      :options="listUnit"
                      label="name"
                      @search="fetchOptionsUnit"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        Không có dữ liệu
                      </span>
                    </v-select>
                    <input class="input_tmp_validator" id="unit_id" />
                  </div>
                </div>
                <div class="p-field position-relative no-padding p-lg-6 p-col-12">
                  <label class="p-col-fixed txt-left"
                    >Mã công ty <span class="span-cus">*</span></label
                  >
                  <div class="p-col">
                    <v-select :clearable="false"
                      title="Mã công ty"
                      id="a2"
                      :filterable="false"
                      placeholder="Chọn"
                      v-model="selCompany"
                      :options="listCompany"
                      label="code"
                      @search="fetchOptionsCompany"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        Không có dữ liệu
                      </span>
                    </v-select>
                    <input class="input_tmp_validator" id="company_id" />
                  </div>
                </div>

                <div class="p-field position-relative no-padding p-lg-6 d-lg-block d-none">
                  <label class="p-col-fixed txt-left"
                    >Hạn sử dụng tiêu chuẩn</label
                  >
                  <div class="p-col">
                    <!-- <label class="p-inputtext p-component p-filled disabled">{{ first_name }} <span class="span-cus">*</span></label> -->
                    <div class="p-inputgroup">
                      <InputNumber
                        mode="decimal"
                        :minFractionDigits="0"
                        v-model="expired_number"
                        placeholder="Nhập"
                        id="expired_number"
                      />
                      <span class="p-inputgroup-addon"> tháng </span>
                    </div>
                  </div>
                </div>

                <div class="p-field position-relative no-padding p-lg-6 p-col-12">
                  <label class="p-col-fixed txt-left">Mã vạch sản phẩm</label>
                  <div class="p-col">
                    <InputText
                      v-model="barcode"
                      placeholder="Nhập"
                      type="text"
                      id="barcode"
                    />
                  </div>
                </div>

                <div class="p-field position-relative no-padding p-lg-6 d-lg-block d-none">
                  <label class="p-col-fixed txt-left">Nhà sản xuất</label>
                  <div class="p-col">
                    <InputText
                      v-model="producer"
                      placeholder="Nhập"
                      type="text"
                      id="producer"
                    />
                  </div>
                </div>

                <div class="p-field position-relative no-padding p-lg-6 p-col-12">
                  <label class="p-col-fixed txt-left">Mã vạch thùng</label>
                  <div class="p-col">
                    <InputText
                      v-model="barcode_bucket"
                      placeholder="Nhập"
                      type="text"
                      id="barcode_bucket"
                    />
                  </div>
                </div>

                <div class="p-field position-relative no-padding p-lg-6 d-lg-block d-none">
                  <label class="p-col-fixed txt-left">Mã vị trí lưu kho</label>
                  <div class="p-col">
                    <InputText
                      v-model="storage_location_code"
                      placeholder="Nhập"
                      type="text"
                      id="storage_location_code"
                    />
                  </div>
                </div>

                <div class="p-field position-relative no-padding p-lg-6 p-col-12">
                  <label class="p-col-fixed txt-left"
                    >Nhóm vật tư hàng hóa <span class="span-cus">*</span></label
                  >
                  <div class="p-col">
                    <v-select :clearable="false"
                      title="Nhóm vật tư hàng hóa"
                      id="a3"
                      :filterable="false"
                      placeholder="Chọn"
                      v-model="selMaterial"
                      :options="listMaterial"
                      label="name"
                      @search="fetchOptionsMaterial"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        Không có dữ liệu
                      </span>
                    </v-select>
                    <input class="input_tmp_validator" id="materials_id" />
                  </div>
                </div>

                <div class="p-field position-relative no-padding col-12 d-lg-none d-block">
                  <label class="p-col-fixed txt-left"
                    >Mã nhóm vật tư hàng hóa
                    <span class="span-cus">*</span></label
                  >
                  <div class="p-col">
                    <InputText
                      v-model="material_code"
                      type="text"
                      id="material_code"
                      disabled="disabled"
                    />
                  </div>
                </div>

                <div class="p-field position-relative no-padding col-12 d-lg-none d-block">
                  <label class="p-col-fixed txt-left"
                    >Đơn vị tính <span class="span-cus">*</span></label
                  >
                  <div class="p-col">
                    <v-select :clearable="false"
                      title="Đơn vị tính"
                      id="a1"
                      :filterable="false"
                      placeholder="Chọn"
                      v-model="selUnit"
                      :options="listUnit"
                      label="name"
                      @search="fetchOptionsUnit"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        Không có dữ liệu
                      </span>
                    </v-select>
                    <input class="input_tmp_validator" id="unit_id" />
                  </div>
                </div>

                <div class="p-field position-relative no-padding col-12 d-lg-none d-block">
                  <label class="p-col-fixed txt-left"
                    >Hạn sử dụng tiêu chuẩn</label
                  >
                  <div class="p-col">
                    <!-- <label class="p-inputtext p-component p-filled disabled">{{ first_name }} <span class="span-cus">*</span></label> -->
                    <div class="p-inputgroup">
                      <InputNumber
                        mode="decimal"
                        :minFractionDigits="0"
                        v-model="expired_number"
                        placeholder="Nhập"
                        id="expired_number"
                      />
                      <span class="p-inputgroup-addon"> tháng </span>
                    </div>
                  </div>
                </div>

                <div class="p-field position-relative no-padding col-12 d-lg-none d-block">
                  <label class="p-col-fixed txt-left">Nhà sản xuất</label>
                  <div class="p-col">
                    <InputText
                      v-model="producer"
                      placeholder="Nhập"
                      type="text"
                      id="producer"
                    />
                  </div>
                </div>

                <div class="p-field position-relative no-padding col-12 d-lg-none d-block">
                  <label class="p-col-fixed txt-left">Mã vị trí lưu kho</label>
                  <div class="p-col">
                    <InputText
                      v-model="storage_location_code"
                      placeholder="Nhập"
                      type="text"
                      id="storage_location_code"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Panel>
          <Panel>
            <div class="row">
              <div class="col-lg-6 col-12">
                <div class="p-formgrid">
                  <div class="p-card-field p-grid">
                    <div class="col-12 no-padding">
                      <h5>QUY CÁCH BAO BÌ CHỨA SẢN PHẨM</h5>
                    </div>

                    <div class="p-field position-relative no-padding p-col-12">
                      <label class="p-col-fixed txt-left">Loại bao bì</label>
                      <div class="p-col">
                        <InputText
                          v-model="packaging_type"
                          type="text"
                          placeholder="Nhập"
                          id="packaging_type"
                        />
                      </div>
                    </div>

                    <div class="p-field position-relative no-padding p-col-12">
                      <label class="p-col-fixed txt-left">Dài</label>
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <InputNumber
                            mode="decimal"
                            v-model="packaging_length"
                            placeholder="Nhập"
                            id="packaging_length"
                            locale="de-DE"
                            :min="0.01"
                            :minFractionDigits="2"
                            :maxFractionDigits="2"
                          />
                          <span class="p-inputgroup-addon"> cm </span>
                        </div>
                      </div>
                    </div>

                    <div class="p-field position-relative no-padding p-col-12">
                      <label class="p-col-fixed txt-left">Rộng</label>
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <InputNumber
                            mode="decimal"
                            v-model="packaging_width"
                            placeholder="Nhập"
                            id="packaging_width"
                            locale="de-DE"
                            :min="0.01"
                            :minFractionDigits="2"
                            :maxFractionDigits="2"
                          />
                          <span class="p-inputgroup-addon"> cm </span>
                        </div>
                      </div>
                    </div>

                    <div class="p-field position-relative no-padding p-col-12">
                      <label class="p-col-fixed txt-left">Cao</label>
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <InputNumber
                            mode="decimal"
                            v-model="packaging_height"
                            placeholder="Nhập"
                            id="packaging_height"
                            locale="de-DE"
                            :min="0.01"
                            :minFractionDigits="2"
                            :maxFractionDigits="2"
                          />
                          <span class="p-inputgroup-addon"> cm </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="p-formgrid">
                  <div class="p-card-field p-grid">
                    <div class="col-12 no-padding">
                      <h5>QUY CÁCH ĐÓNG GÓI</h5>
                    </div>

                    <div class="p-field position-relative no-padding p-col-12">
                      <label class="p-col-fixed txt-left"
                        >Số lượng sản phẩm/ bao bì</label
                      >
                      <div class="p-col">
                        <InputNumber
                          mode="decimal"
                          v-model="regs_pack_number_product_pack"
                          placeholder="Nhập"
                          id="regs_pack_number_product_pack"
                          locale="de-DE"
                          :min="1"
                          :minFractionDigits="0"
                        />
                      </div>
                    </div>

                    <div class="p-field position-relative no-padding p-col-12">
                      <label class="p-col-fixed txt-left"
                        >Số lượng bao bì/ pallet</label
                      >
                      <div class="p-col">
                        <InputNumber
                          mode="decimal"
                          v-model="regs_pack_number_pack_pallet"
                          placeholder="Nhập"
                          id="regs_pack_number_pack_pallet"
                          locale="de-DE"
                          :min="1"
                          :minFractionDigits="0"
                        />
                      </div>
                    </div>

                    <div class="p-field position-relative no-padding p-col-12">
                      <label class="p-col-fixed txt-left w-100"
                        >Số thùng hàng xếp chồng tối đa/ pallet</label
                      >
                      <div class="p-col">
                        <InputNumber
                          mode="decimal"
                          :minFractionDigits="0"
                          v-model="regs_pack_number_bucket_pallet"
                          placeholder="Nhập"
                          locale="de-DE"
                          :min="1"
                          id="regs_pack_number_bucket_pallet"
                        />
                      </div>
                    </div>

                    <div class="p-field position-relative no-padding p-col-12">
                      <label class="p-col-fixed txt-left"
                        >Độ cao xếp chồng pallet tối đa</label
                      >
                      <div class="p-col">
                        <InputNumber
                          mode="decimal"
                          :minFractionDigits="2"
                          :maxFractionDigits="2"
                          v-model="regs_pack_height"
                          locale="de-DE"
                          :min="0.01"
                          placeholder="Nhập"
                          id="regs_pack_height"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Panel>

          <Panel>
            <div class="row">
              <div
                class="col-12"
                style="
                  padding-left: 24px !important;
                  padding-top: 8px !important;
                "
              >
                <h5 style="margin-bottom: 0 !important">ĐẶC TÍNH SẢN PHẨM</h5>
              </div>
              <div class="col-lg-6 col-12" style="margin-top: 16px !important">
                <div class="p-formgrid">
                  <div class="p-card-field p-grid responsive-pb-2 pt-0">
                    <div class="p-field position-relative no-padding p-col-12">
                      <label class="p-col-fixed txt-left">Xuất xứ</label>
                      <div class="p-col">
                        <v-select :clearable="false"
                          title="Xuất xứ"
                          id="a4"
                          :filterable="false"
                          placeholder="Chọn"
                          v-model="selOrigin"
                          :options="listOrigin"
                          label="name"
                          @search="fetchOptionsOrigin"
                        >
                          <span
                            slot="no-options"
                            @click="$refs.select.open = false"
                          >
                            Không có dữ liệu
                          </span>
                        </v-select>
                      </div>
                    </div>

                    <div class="p-field position-relative no-padding p-col-12">
                      <label class="p-col-fixed txt-left"
                        >Thời hạn bảo hành</label
                      >
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <InputNumber
                            mode="decimal"
                            :minFractionDigits="0"
                            v-model="time_warranty"
                            locale="de-DE"
                            :min="0"
                            placeholder="Nhập 0 nếu không bảo hành"
                            id="time_warranty"
                          />
                          <span class="p-inputgroup-addon"> tháng </span>
                        </div>
                      </div>
                    </div>

                    <div class="p-field position-relative no-padding p-col-12">
                      <label class="p-col-fixed txt-left"
                        >Nhiệt độ bảo quản</label
                      >
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <InputNumber
                            mode="decimal"
                            :minFractionDigits="0"
                            locale="de-DE"
                            v-model="temperature"
                            placeholder="Nhập"
                            id="temperature"
                          />
                          <span class="p-inputgroup-addon"> °C </span>
                        </div>
                      </div>
                    </div>

                    <div class="p-field position-relative no-padding p-col-12">
                      <label class="p-col-fixed txt-left">Độ ẩm</label>
                      <div class="p-col">
                        <InputNumber
                          mode="decimal"
                          :minFractionDigits="0"
                          :maxFractionDigits="2"
                          locale="de-DE"
                          v-model="humidity"
                          placeholder="Nhập"
                          :min="0"
                          id="humidity"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-6 col-12"
                style="@media (max-width: 960px) {padding-top: 0px !important}"
              >
                <div class="p-formgrid">
                  <div class="p-card-field p-grid responsive-no-pt">
                    <!-- <div class="col-12">
                                <h5 style="visibility:hidden">QUY CÁCH ĐÓNG GÓI</h5>
                              </div> -->
                    <div class="p-field position-relative no-padding p-col-12">
                      <label class="p-col-fixed txt-left">Thành phần</label>
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <Textarea
                            rows="3"
                            v-model="ingredient"
                            :autoResize="true"
                            placeholder="Nhập"
                            id="ingredient"
                            maxlength="14000"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="p-field position-relative no-padding p-col-6 ml-0">
                      <div
                        class="d-flex justify-content-start align-items-center mb-3 mt-2"
                      >
                        <Checkbox
                          :binary="true"
                          id="sub_lable"
                          v-model="sub_lable"
                        />
                        <label class="pl-3 mb-1" for="sub_lable"
                          >Nhãn phụ</label
                        >
                      </div>
                      <div
                        class="d-flex justify-content-start align-items-center mb-3"
                      >
                        <Checkbox
                          :binary="true"
                          id="sub_stamp"
                          v-model="sub_stamp"
                        />
                        <label class="pl-3 mb-1" for="sub_stamp">Tem phụ</label>
                      </div>
                      <div
                        class="d-flex justify-content-start align-items-center mb-3"
                      >
                        <Checkbox
                          :binary="true"
                          id="anti_counterfeiting_stamps"
                          v-model="anti_counterfeiting_stamps"
                        />
                        <label
                          class="pl-3 mb-1"
                          for="anti_counterfeiting_stamps"
                          >Tem chống hàng giả</label
                        >
                      </div>
                      <div
                        class="d-flex justify-content-start align-items-center mb-3"
                      >
                        <Checkbox
                          :binary="true"
                          id="import_stamp"
                          v-model="import_stamp"
                        />
                        <label class="pl-3 mb-1" for="import_stamp"
                          >Tem nhập khẩu</label
                        >
                      </div>
                      <div
                        class="d-flex justify-content-start align-items-center"
                      >
                        <Checkbox
                          :binary="true"
                          id="fragile"
                          v-model="fragile"
                        />
                        <label class="pl-3 mb-1" for="fragile"
                          >Hàng dễ vỡ</label
                        >
                      </div>
                    </div>
                    <div class="p-field position-relative no-padding p-col-5 ml-0">
                      <div
                        class="d-flex justify-content-start align-items-center mb-3 mt-2"
                      >
                        <Checkbox
                          :binary="true"
                          id="defrosting_stamp"
                          v-model="defrosting_stamp"
                        />
                        <label class="pl-3 mb-1" for="defrosting_stamp"
                          >Tem hàng rã đông</label
                        >
                      </div>
                      <div
                        class="d-flex justify-content-start align-items-center"
                      >
                        <Checkbox
                          :binary="true"
                          id="cool_stamps"
                          v-model="cool_stamps"
                        />
                        <label class="pl-3 mb-1" for="cool_stamps"
                          >Tem hàng mát</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Panel>

          <Panel>
            <div class="row">
              <div class="col-12">
                <div class="p-formgrid">
                  <div class="p-card-field p-grid">
                    <div class="col-12 no-padding">
                      <h5>THÔNG TIN NHÀ CUNG CẤP</h5>
                    </div>

                    <div class="col-lg-5 col-12 no-padding">
                      <label class="p-col-fixed txt-left">Nhà cung cấp</label>
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <InputText
                            v-model="supplier_name"
                            placeholder="Nhập"
                            id="supplier_name"
                            maxlength="255"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-lg-2 col-12 no-padding responsive-padding-left"
                    >
                      <label class="p-col-fixed txt-left"
                        >Thời gian đặt hàng</label
                      >
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <InputNumber
                            mode="decimal"
                            :minFractionDigits="0"
                            locale="de-DE"
                            v-model="number_time_book"
                            placeholder="Nhập"
                            id="number_time_book"
                            :min="1"
                          />
                          <span class="p-inputgroup-addon"> ngày </span>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-lg-2 col-12 no-padding responsive-padding-left"
                    >
                      <label class="p-col-fixed txt-left"
                        >SP đặt hàng tối thiểu</label
                      >
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <InputNumber
                            mode="decimal"
                            :minFractionDigits="0"
                            locale="de-DE"
                            v-model="min_order_product"
                            placeholder="Nhập"
                            id="min_order_product"
                            :min="1"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-lg-3 col-12 pr-0 pl-0 no-padding responsive-padding-left"
                    >
                      <label class="p-col-fixed txt-left"
                        >Chi phí đặt hàng/ lần</label
                      >
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <InputNumber
                            mode="decimal"
                            :minFractionDigits="0"
                            v-model="ordering_costs"
                            locale="de-DE"
                            placeholder="Nhập"
                            :min="1"
                            id="ordering_costs"
                          />
                          <Button
                            type="button"
                            icon="pi pi-plus"
                            class="p-button-success ml-2 px-3 rounded"
                            @click="addsupplier"
                            severity="success"
                            style="width: 46px !important; height: 46px !important;"
                            />
                        </div>
                      </div>
                    </div>

                    <div class="col-12 mt-lg-4 mt-3 no-padding">
                      <DataTable
                        :value="suppliers"
                        class="p-datatable-responsive"
                        responsiveLayout="scroll"
                      >
                        <Column header="STT" headerStyle="width: 5%">
                          <span class="responsive-label">STT</span>
                          <template #body="slotProps">
                            <span class="responsive-label">{{ getLabel(slotProps) }}</span>
                            {{ slotProps.index + 1 }}
                          </template>
                        </Column>
                        <Column
                          field="name"
                          header="Nhà cung cấp"
                          headerStyle="width: 40%"
                        >
                          <template #body="slotProps">
                            <span class="responsive-label">{{ getLabel(slotProps) }}</span>
                            <div class="text-right">
                              {{ slotProps.data.name }}
                            </div>
                          </template>
                        </Column>
                        <Column
                          field="number_time_book"
                          header="Thời gian đặt hàng (Ngày)"
                        >
                          <template #body="slotProps">
                            <span class="responsive-label">{{ getLabel(slotProps) }}</span>
                            <div class="text-right">
                              {{
                                $commonFuction.numberFormat(slotProps.data.number_time_book,0)
                              }}
                            </div>
                          </template>
                        </Column>
                        <Column
                          field="min_order_product"
                          header="Số sản phẩm đặt hàng tối thiểu"
                          class="text-right"
                        >
                          <template #body="slotProps">
                            <span class="responsive-label">{{ getLabel(slotProps) }}</span>
                            <div class="text-right">
                              {{
                                $commonFuction.numberFormat(slotProps.data.min_order_product,0)
                              }}
                            </div>
                          </template>
                        </Column>
                        <Column
                          field="ordering_costs"
                          header="Chi phí đặt hàng"
                          class="text-right"
                        >
                          <template #body="slotProps">
                            <span class="responsive-label">{{ getLabel(slotProps) }}</span>
                            <div class="text-right">
                              {{
                                $commonFuction.numberFormat(slotProps.data.ordering_costs,0)
                              }}
                            </div>
                          </template>
                        </Column>
                        <Column header="" headerStyle="width: 5%">
                          <template #body="slotProps">
                            <span class="responsive-label">Hành động</span>
                            <div class="text-center">
                              <Button
                                type="button"
                                icon="pi pi-trash"
                                class="p-button-danger"
                                @click="clearsupplier(slotProps.index)"
                              />
                            </div>
                          </template>
                        </Column>

                        <template #empty>
                          <div class="text-center">
                            {{ $t("datatable.empty") }}
                          </div>
                        </template>
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Panel>

          <Panel>
            <div class="row">
              <div class="col-12">
                <div class="p-formgrid">
                  <div class="p-card-field p-grid">
                    <div class="col-12 no-padding">
                      <h5>THÔNG TIN ĐẶT HÀNG</h5>
                    </div>

                    <div class="p-field position-relative col-lg-6 col-12 no-padding">
                      <label class="p-col-fixed txt-left"
                        >Tồn kho tối thiểu</label
                      >
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <InputNumber
                            :minFractionDigits="0"
                            v-model="info_book_min_inventory"
                            placeholder="Nhập"
                            locale="de-DE"
                            id="info_book_min_inventory"
                            :min="1"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="p-field position-relative col-lg-6 col-12 no-padding d-lg-block d-none"
                    >
                      <label class="p-col-fixed txt-left"
                        >Lượng đặt hàng tối ưu</label
                      >
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <InputNumber
                            mode="decimal"
                            :minFractionDigits="0"
                            v-model="info_book_book_optimal"
                            placeholder="Nhập"
                            locale="de-DE"
                            id="info_book_book_optimal"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="p-field position-relative col-lg-6 col-12 no-padding">
                      <label class="p-col-fixed txt-left"
                        >Tồn kho an toàn</label
                      >
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <InputNumber
                            mode="decimal"
                            :minFractionDigits="0"
                            v-model="info_book_min_safe"
                            placeholder="Nhập"
                            locale="de-DE"
                            id="info_book_min_safe"
                            :min="1"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="p-field position-relative col-lg-6 col-12 no-padding d-lg-block d-none"
                    >
                      <label class="p-col-fixed txt-left"
                        >Thời gian đặt hàng tối ưu (ngày)</label
                      >
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <InputNumber
                            mode="decimal"
                            :minFractionDigits="0"
                            v-model="info_book_time_book_optimal"
                            placeholder="Nhập"
                            locale="de-DE"
                            id="info_book_time_book_optimal"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="p-field position-relative col-lg-6 col-12 no-padding">
                      <label class="p-col-fixed txt-left"
                        >Chi phí lưu kho/ SKU</label
                      >
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <InputNumber
                            mode="decimal"
                            :minFractionDigits="2"
                            :maxFractionDigits="2"
                            v-model="info_book_storage_costs"
                            placeholder="Nhập"
                            locale="de-DE"
                            id="info_book_storage_costs"
                            :min="0.01"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-12 no-padding d-lg-none d-block">
                      <label class="p-col-fixed txt-left"
                        >Lượng đặt hàng tối ưu</label
                      >
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <InputNumber
                            mode="decimal"
                            :minFractionDigits="0"
                            v-model="info_book_book_optimal"
                            placeholder="Nhập"
                            locale="de-DE"
                            id="info_book_book_optimal"
                            :min="1"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-12 no-padding d-lg-none d-block">
                      <label class="p-col-fixed txt-left"
                        >Thời gian đặt hàng tối ưu (ngày)</label
                      >
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <InputNumber
                            mode="decimal"
                            :minFractionDigits="0"
                            locale="de-DE"
                            v-model="info_book_time_book_optimal"
                            placeholder="Nhập"
                            id="info_book_time_book_optimal"
                            :min="1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Panel>

          <Panel>
            <div class="row">
              <div class="col-12">
                <div class="p-formgrid">
                  <div class="p-card-field p-grid">
                    <div class="col-12 no-padding">
                      <h5>THÔNG TIN KHÁC</h5>
                    </div>

                    <div class="p-field position-relative col-lg-6 col-12 no-padding">
                      <label class="p-col-fixed txt-left"
                        >Mã hồ sơ công bố</label
                      >
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <InputText
                            v-model="code_profile_public"
                            placeholder="Nhập"
                            id="code_profile_public"
                            maxlength="255"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="p-field position-relative col-lg-6 col-12 no-padding d-lg-block d-none"
                    >
                      <label class="p-col-fixed txt-left"
                        >Link hình ảnh sản phẩm</label
                      >
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <InputText
                            v-model="link_image"
                            type="text"
                            placeholder="Nhập"
                            id="link_image"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="p-field position-relative col-lg-6 col-12 no-padding">
                      <label class="p-col-fixed txt-left"
                        >Phiếu kết quả kiểm nghiệm (mã số phiếu)</label
                      >
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <InputText
                            v-model="code_profile"
                            type="text"
                            maxlength="255"
                            placeholder="Nhập"
                            id="code_profile"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="p-field position-relative col-lg-6 col-12 no-padding d-lg-block d-none"
                    >
                      <label class="p-col-fixed txt-left"
                        >Link thông tin phiếu kiểm nghiệm</label
                      >
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <InputText
                            v-model="link"
                            type="text"
                            placeholder="Nhập"
                            id="link"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="p-field position-relative col-lg-6 col-12 no-padding">
                      <label class="p-col-fixed txt-left"
                        >Thời gian hiệu lực</label
                      >
                      <div class="p-col">
                        <div
                          class="d-flex justify-content-start align-items-center mb-3"
                        >
                          <Checkbox
                            :binary="true"
                            v-model="infinite_date"
                            id="infinite_date"
                          />
                          <label class="pl-3 m-0" for="infinite_date"
                            >Vô thời hạn</label
                          >
                        </div>
                        <div class="col-12 p-0">
                          <Calendar
                            :disabled="infinite_date"
                            v-model="date_profile"
                            :minDate="new Date()"
                            placeholder="Nhập"
                            id="date_profile"
                            :showButtonBar="true"
                            dateFormat="dd/mm/yy"
                            :showIcon="true"
                          />
                        </div>
                      </div>
                      <div class="d-lg-block d-none">

                        <label class="p-col-fixed txt-left"
                        >Trạng thái kinh doanh</label
                        >
                        <div class="p-col">
                          <div class="p-inputgroup">
                            <Dropdown
                            v-model="status"
                            :options="listActive"
                            optionLabel="label"
                            />
                          </div>
                        </div>
                      </div>
                      </div>

                    <div
                      class="p-field position-relative col-lg-6 col-12 no-padding d-lg-block d-none"
                    >
                      <label class="p-col-fixed txt-left">Ghi chú</label>
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <Textarea
                            rows="3"
                            v-model="note"
                            :autoResize="true"
                            placeholder="Nhập"
                            id="note"
                            maxlength="14000"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="p-field position-relative col-lg-6 col-12 no-padding d-lg-none">
                      <label class="p-col-fixed txt-left"
                        >Trạng thái kinh doanh</label
                      >
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <Dropdown
                            v-model="status"
                            :options="listActive"
                            optionLabel="label"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-12 no-padding d-lg-none d-block">
                      <label class="p-col-fixed txt-left"
                        >Link hình ảnh sản phẩm</label
                      >
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <InputText
                            v-model="link_image"
                            type="text"
                            placeholder="https://images.app.goo.gl/"
                            id="link_image"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-12 no-padding d-lg-none d-block">
                      <label class="p-col-fixed txt-left"
                        >Link thông tin phiếu kiểm nghiệm</label
                      >
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <InputText
                            v-model="link"
                            type="text"
                            placeholder="https://"
                            id="link"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-12 no-padding d-lg-none d-block">
                      <label class="p-col-fixed txt-left">Ghi chú</label>
                      <div class="p-col">
                        <div class="p-inputgroup">
                          <Textarea
                            rows="3"
                            v-model="note"
                            :autoResize="true"
                            placeholder="Nhập"
                            id="note"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </div>
      </div>
      <Toolbar class="fixed-bottom-toolbar">
        <template slot="right">
          <Button
            :label="$constants.TEXT_BUTTON.BACK"
            @click="backToList()"
            class="p-button-warning callback-btn"
          />
          <Button
            v-if="mode == 'edit'"
            :label="$constants.TEXT_BUTTON.EDIT"
            @click="saveData()"
            class="main-style-button"
          />
          <Button
            v-else
            :label="$constants.TEXT_BUTTON.ADD"
            @click="saveData()"
            class="main-style-button"
          />
        </template>
      </Toolbar>
    </div>
  </form>
</template>

<script>
import moment from "moment-timezone";
import DataServices from "@/core/DataServices";
// import ApiRepository from "@/core/ApiRepository";
// import BaseDatatable from './list'
export default {
  components: {
    // BaseDatatable
  },
  props: {
    mode: String,
    model: Object,
    users: Array,
    triggerSave: Boolean,
  },
  data() {
    return {
      code: "",
      name: "",
      company_id: "",
      expired_number: null,
      barcode: "",
      barcode_bucket: "",
      storage_location_code: "",
      regs_pack_height: null,
      regs_pack_number_bucket_pallet: null,
      regs_pack_number_pack_pallet: null,
      regs_pack_number_product_pack: null,
      packaging_height: null,
      packaging_width: null,
      packaging_length: null,
      packaging_type: null,
      producer: "",
      humidity: null,
      temperature: null,
      time_warranty: null,
      origin_id: null,
      ingredient: "",
      sub_lable: false,
      sub_stamp: false,
      anti_counterfeiting_stamps: false,
      fragile: false,
      defrosting_stamp: false,
      import_stamp: false,
      cool_stamps: false,
      suppliers: [],
      supplier_name: "",
      number_time_book: null,
      min_order_product: null,
      ordering_costs: null,
      status: { value: 1, label: "Đang kinh doanh" },
      infinite_date: false,
      info_book_min_inventory: null,
      info_book_min_safe: null,
      info_book_storage_costs: null,
      info_book_book_optimal: null,
      info_book_time_book_optimal: null,
      code_profile_public: "",
      code_profile: "",
      date_profile: null,
      note: "",
      link: "",
      link_image: "",
      material_code: "",
      models: {},
      componentKey: 0,
      filters: {},
      time_zone_local: moment.tz.guess(true),
      DateRange:
        new Date().getFullYear() - 100 + ":" + (new Date().getFullYear() + 100),
      listActive: [
          {value:3, label:"Hết hàng"},
          {value:2, label:"Hàng bỏ mẫu"},
          {value:1,label:"Đang kinh doanh"},
          {value:0,label:"Ngừng kinh doanh"}
      ],
      selUnit: null,
      listUnit: [],
      listUnitAll: [],
      selCompany: null,
      listCompany: [],
      listCompanyAll: [],
      selMaterial: null,
      listMaterial: [],
      listMaterialAll: [],

      selOrigin: null,
      listOrigin: [],
      listOriginAll: [],
    };
  },
  watch: {
    triggerSave() {
      if (this.triggerSave) {
        this.saveData();
      }
    },
    selMaterial() {
      if (this.selMaterial) {
        this.material_code = this.selMaterial?.code;
      }
    },

    infinite_date() {
      if (this.infinite_date) {
        this.date_profile = null;
      }
    },
  },
  async mounted() {
    if (this.$route.params.id !== undefined && this.$route.params.id !== null) {
      await this.getData(this.$route.params.id);
    }
  },
  async created() {
    await this.getListCompany();
    await this.getListUnit();
    await this.getListMaterial();
    await this.getListOrigin();
  },
  methods: {
    getLabel(col) {
      // if (col.label) return col.label;
      // else return col.name;
      console.log(col.column.header);
      return col.column.header;
    },
    resetsupplier() {
      this.supplier_name = null;
      this.number_time_book = null;
      this.min_order_product = null;
      this.ordering_costs = null;
    },
    addsupplier() {
      if (
        !this.$commonFuction.isEmpty(this.supplier_name) &&
        !this.$commonFuction.isEmpty(this.number_time_book) &&
        !this.$commonFuction.isEmpty(this.min_order_product) &&
        !this.$commonFuction.isEmpty(this.ordering_costs)
      ) {
        this.suppliers.push({
          name: this.supplier_name,
          number_time_book: this.number_time_book,
          min_order_product: this.min_order_product,
          ordering_costs: this.ordering_costs,
        });
        this.resetsupplier();
      } else {
        this.$toast.add({
          severity: "error",
          summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
          detail: "Vui lòng nhập đủ thông tin!",
          life: 3000,
        });
      }
    },
    clearsupplier(index) {
      this.suppliers.splice(index, 1);
    },
    clickEdit() {
      this.$router.push({
        path: "/admin/list-product/edit/" + this.models.id,
        params: { id: this.models.id },
      });
    },
    backToList() {
      this.$router.push({ path: "/admin/list-product" });
    },
    clickDelete() {
      this.deleteId = this.models.id;
      this.deleteName = this.models.fullname;
      this.showDeleteDialog = true;
    },

    async getListOrigin() {
      var where_key = {
        deleted: { _eq: false },
        active: { _eq: true },
      };
      let { data } = await this.$apollo.query({
        query: DataServices.getList("origin", { fields: `id name` }),
        variables: {
          where_key: where_key,
          orderBy: [{ ordinal: "desc" }],
        },
        fetchPolicy: "network-only",
      });
      var list = data[Object.keys(data)];
      var list_origin = [];
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_origin.push({ id: e.id, name: e.name });
      }
      this.listOrigin = list_origin;
      this.listOriginAll = list_origin;
    },

    async fetchOptionsOrigin(search, loading) {
      console.log(loading);
      if (search !== "" && search !== null) {
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted: { _eq: false },
          active: { _eq: true },
          name: {
            _iregex: this.$commonFuction.search(search),
          },
        };

        let { data } = await this.$apollo.query({
          query: DataServices.getList("origin", { fields: `id name` }),
          variables: {
            where_key: where_key,
            orderBy: [{ ordinal: "asc" }],
          },
          fetchPolicy: "network-only",
        });
        var list = data[Object.keys(data)];
        var list_origin = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_origin.push({ id: e.id, name: e.name });
        }
        this.listOrigin = list_origin;
      } else {
        // cosnole.log("dasdasd")
        this.listOrigin = this.listOriginAll;
      }
    },

    async getListMaterial() {
      var where_key = {
        deleted: { _eq: false },
      };
      let { data } = await this.$apollo.query({
        query: DataServices.getList("materials", { fields: `id name code` }),
        variables: {
          where_key: where_key,
          orderBy: [{ ordinal: "asc" }],
        },
        fetchPolicy: "network-only",
      });
      var list = data[Object.keys(data)];
      var list_materials = [];
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_materials.push({ id: e.id, name: e.name, code: e.code });
      }
      this.listMaterial = list_materials;
      this.listMaterialAll = list_materials;
    },

    async fetchOptionsMaterial(search, loading) {
      console.log(loading);
      if (search !== "" && search !== null) {
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted: { _eq: false },
          name: {
            _ilike: "%" + this.$commonFuction.SkipVN(search).trim() + "%",
          },
        };
        let { data } = await this.$apollo.query({
          query: DataServices.getList("materials", { fields: `id name code` }),
          variables: {
            where_key: where_key,
            orderBy: [{ ordinal: "asc" }],
          },
          fetchPolicy: "network-only",
        });
        var list = data[Object.keys(data)];
        var list_material = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_material.push({ id: e.id, name: e.name, code: e.code });
        }
        this.listMaterial = list_material;
      } else {
        // cosnole.log("dasdasd")
        this.listMaterial = this.listMaterialAll;
      }
    },

    async getListCompany() {
      var where_key = {
        deleted: { _eq: false },
      };
      let { data } = await this.$apollo.query({
        query: DataServices.getList("company", { fields: `id code name` }),
        variables: {
          where_key: where_key,
          orderBy: [{ created_at: "desc" }],
        },
        fetchPolicy: "network-only",
      });
      var list = data[Object.keys(data)];
      var list_company = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_company.push({ id: e.id, name: e.name, code: e.code });
      }
      this.listCompany = list_company;
      this.listCompanyAll = list_company;
    },

    async fetchOptionsCompany(search, loading) {
      console.log(loading);
      if (search !== "" && search !== null) {
        var where_key = {
          deleted: { _eq: false },
          _or: {
            code: {
              _ilike: "%" + this.$commonFuction.SkipVN(search).trim() + "%",
            },
          },
        };
        let { data } = await this.$apollo.query({
          query: DataServices.getList("company", { fields: `id code name` }),
          variables: {
            where_key: where_key,
            orderBy: [{ created_at: "desc" }],
          },
          fetchPolicy: "network-only",
        });
        var list = data[Object.keys(data)];
        var list_company = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_company.push({ id: e.id, name: e.name, code: e.code });
        }
        this.listCompany = list_company;
      } else {
        // cosnole.log("dasdasd")
        this.listCompany = this.listCompanyAll;
      }
    },
    async getListUnit() {
      var where_key = {
        deleted: { _eq: false },
      };
      let { data } = await this.$apollo.query({
        query: DataServices.getList("unit", { fields: `id name` }),
        variables: {
          where_key: where_key,
          orderBy: [{ ordinal: "asc" }],
        },
        fetchPolicy: "network-only",
      });
      var list = data[Object.keys(data)];
      var list_unit = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_unit.push({ id: e.id, name: e.name });
      }
      this.listUnit = list_unit;
      this.listUnitAll = list_unit;
    },
    async fetchOptionsUnit(search, loading) {
      console.log(loading);
      if (search !== "" && search !== null) {
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted: { _eq: false },
          name: {
            _ilike: "%" + this.$commonFuction.SkipVN(search).trim() + "%",
          },
        };
        let { data } = await this.$apollo.query({
          query: DataServices.getList("unit", { fields: `id name` }),
          variables: {
            where_key: where_key,
            orderBy: [{ ordinal: "asc" }],
          },
          fetchPolicy: "network-only",
        });
        var list = data[Object.keys(data)];
        var list_unit = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_unit.push({ id: e.id, name: e.name });
        }
        this.listUnit = list_unit;
      } else {
        // cosnole.log("dasdasd")
        this.listUnit = this.listUnitAll;
      }
    },
    // async IsEmail(email){
    //   const emailRegexp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    //   return emailRegexp.test(email)
    // },
    validateDate(dateString) {
      const dateFormat = 'DD/MM/YYYY';  
      const parsedDate = moment(dateString, dateFormat, true);
      if (parsedDate.format(dateFormat) == "Invalid date") {
        return false;
      }
      // console.log("validating date", parsedDate, parsedDate.isValid(), parsedDate.format(dateFormat), dateString);
      return parsedDate.isValid();
    },
    async validateData() {
      this.validationErrors = {};

      let code_add = document.getElementById("code");
      if (this.$commonFuction.isEmpty(this.code)) {
        code_add.setCustomValidity("Mã sản phẩm không được để trống!");
      } else {
        code_add.setCustomValidity("");
      }

      let material_code_add = document.getElementById("material_code");
      if (this.$commonFuction.isEmpty(this.material_code)) {
        material_code_add.setCustomValidity(
          "Mã nhóm VTHH không được để trống!"
        );
      } else {
        material_code_add.setCustomValidity("");
      }

      let name_add = document.getElementById("name");
      if (this.$commonFuction.isEmpty(this.name)) {
        name_add.setCustomValidity("Tên sản phẩm không được để trống!");
      } else if (this.name && this.name.length > 255) {
        name_add.setCustomValidity("Tên sản phẩm không được quá 255 ký tự!");
      } else {
        name_add.setCustomValidity("");
      }

      let unit_add = document.getElementById("unit_id");
      if (this.$commonFuction.isEmptyObject(this.selUnit)) {
        unit_add.setCustomValidity("Đơn vị tính không được để trống!");
      } else {
        unit_add.setCustomValidity("");
      }

      let company_add = document.getElementById("company_id");
      if (this.$commonFuction.isEmptyObject(this.selCompany)) {
        company_add.setCustomValidity("Mã công ty không được để trống!");
      } else {
        company_add.setCustomValidity("");
      }
      
      let barcode_add = document.getElementById("barcode");
      if (this.barcode && this.barcode.length > 255) {
        barcode_add.setCustomValidity("Mã vạch sản phẩm không được quá 255 ký tự!");
      } else {
        barcode_add.setCustomValidity("");
      }
      
      let barcode_bucket_add = document.getElementById("barcode_bucket");
      if (this.barcode_bucket && this.barcode_bucket.length > 255) {
        barcode_bucket_add.setCustomValidity("Mã vạch thùng không được quá 255 ký tự!");
      } else {
        barcode_bucket_add.setCustomValidity("");
      }
      
      let producer_add = document.getElementById("producer");
      if (this.producer && this.producer.length > 255) {
        producer_add.setCustomValidity("Nhà sản xuất không được quá 255 ký tự!");
      } else {
        producer_add.setCustomValidity("");
      }
      
      let storage_location_code_add = document.getElementById("storage_location_code");
      if (this.storage_location_code && this.storage_location_code.length > 255) {
        storage_location_code_add.setCustomValidity("Vị trí lưu kho không được quá 255 ký tự!");
      } else {
        storage_location_code_add.setCustomValidity("");
      }
      
      let packaging_type_add = document.getElementById("packaging_type");
      if (this.packaging_type && this.packaging_type.length > 255) {
        packaging_type_add.setCustomValidity("Loại bao bì không được quá 255 ký tự!");
      } else {
        packaging_type_add.setCustomValidity("");
      }
      
      

      let material_add = document.getElementById("materials_id");
      if (this.$commonFuction.isEmptyObject(this.selMaterial)) {
        material_add.setCustomValidity("Nhóm VTHH không được để trống!");
      } else {
        material_add.setCustomValidity("");
      }
      
      let packaging_length_add = document.getElementById("packaging_length");
      if (this.packaging_length && this.packaging_length <= 0) {
        packaging_length_add.setCustomValidity("Dài phải lớn hơn 0!");
      } else {
        packaging_length_add.setCustomValidity("");
      }
      
      let packaging_height_add = document.getElementById("packaging_height");
      if (this.packaging_height && this.packaging_height <= 0) {
        packaging_height_add.setCustomValidity("Cao phải lớn hơn 0!");
      } else {
        packaging_height_add.setCustomValidity("");
      }
      
      let packaging_width_add = document.getElementById("packaging_width");
      if (this.packaging_width && this.packaging_width <= 0) {
        packaging_width_add.setCustomValidity("Rộng phải lớn hơn 0!");
      } else {
        packaging_width_add.setCustomValidity("");
      }
      
      let regs_pack_number_product_pack_add = document.getElementById("regs_pack_number_product_pack");
      if (this.regs_pack_number_product_pack && this.regs_pack_number_product_pack <= 0) {
        regs_pack_number_product_pack_add.setCustomValidity("Số lượng sản phẩm/ bao bì phải lớn hơn 0!");
      } else {
        regs_pack_number_product_pack_add.setCustomValidity("");
      }
      
      let regs_pack_number_pack_pallet_add = document.getElementById("regs_pack_number_pack_pallet");
      if (this.regs_pack_number_pack_pallet && this.regs_pack_number_pack_pallet <= 0) {
        regs_pack_number_pack_pallet_add.setCustomValidity("Số thùng hàng xếp chồng tối đa/ pallet phải lớn hơn 0!");
      } else {
        regs_pack_number_pack_pallet_add.setCustomValidity("");
      }
      
      
      let regs_pack_number_bucket_pallet_add = document.getElementById("regs_pack_number_bucket_pallet");
      if (this.regs_pack_number_bucket_pallet && this.regs_pack_number_bucket_pallet <= 0) {
        regs_pack_number_bucket_pallet_add.setCustomValidity("Số lượng bao bì/ pallet phải lớn hơn 0!");
      } else {
        regs_pack_number_bucket_pallet_add.setCustomValidity("");
      }
      
      let regs_pack_height_add = document.getElementById("regs_pack_height");
      if (this.regs_pack_height && this.regs_pack_height <= 0) {
        regs_pack_height_add.setCustomValidity("Độ cao xếp chồng pallet tối đa phải lớn hơn 0!");
      } else {
        regs_pack_height_add.setCustomValidity("");
      }
      
      let ingredient_add = document.getElementById("ingredient");
      if (this.ingredient && this.ingredient.length > 14000) {
        ingredient_add.setCustomValidity("Thành phần không được quá 14000 ký tự!");
      } else {
        ingredient_add.setCustomValidity("");
      }
      
      let date_profile_add = document.getElementById("date_profile");
      if (this.date_profile && !this.validateDate(this.date_profile)) {
        date_profile_add.setCustomValidity("Vui lòng nhập đúng định dạng DD/MM/YYYY!");
      } else {
        date_profile_add.setCustomValidity("");
      }

      if (this.status == null) console.log("meo meo");
      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        console.log("test=====================");
        inpObj.reportValidity();
        return true;
      }
    },
    async saveData() {
      this.$commonFuction.is_loading(true);
      this.$emit("update:triggerSave", false);
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return;
      }

      this.submitData();
    },
    async submitData() {
      this.$commonFuction.is_loading(true);
      this.showWarningDialog = false;
      console.log("asdfasdf");
      var params = {
        code: this.code,
        name: this.name,
        company_id: this.selCompany?.id,
        expired_number: this.expired_number,
        barcode: this.barcode,
        barcode_bucket: this.barcode_bucket,
        storage_location_code: this.storage_location_code,
        regs_pack_height: this.regs_pack_height,
        regs_pack_number_bucket_pallet: this.regs_pack_number_bucket_pallet,
        regs_pack_number_pack_pallet: this.regs_pack_number_pack_pallet,
        regs_pack_number_product_pack: this.regs_pack_number_product_pack,
        packaging_height: this.packaging_height,
        packaging_width: this.packaging_width,
        packaging_length: this.packaging_length,
        packaging_type: this.packaging_type,
        producer: this.producer,
        humidity: (!this.$commonFuction.isEmpty(this.humidity)) ? `${this.humidity}`:null,
        temperature: this.temperature,
        time_warranty: this.time_warranty,
        origin_id: this.selOrigin?.id,
        ingredient: this.ingredient,
        sub_lable: this.sub_lable,
        sub_stamp: this.sub_stamp,
        anti_counterfeiting_stamps: this.anti_counterfeiting_stamps,
        fragile: this.fragile,
        defrosting_stamp: this.defrosting_stamp,
        import_stamp: this.import_stamp,
        cool_stamps: this.cool_stamps,
        suppliers: this.suppliers,
        // supplier_name: '',
        // number_time_book: null,
        // min_order_product: null,
        // ordering_costs: null,
        infinite_date: this.infinite_date,
        info_book_min_inventory: this.info_book_min_inventory,
        info_book_min_safe: this.info_book_min_safe,
        info_book_storage_costs: this.info_book_storage_costs,
        info_book_book_optimal: this.info_book_book_optimal,
        info_book_time_book_optimal: this.info_book_time_book_optimal,
        code_profile_public: this.code_profile_public,
        code_profile: this.code_profile,
        date_profile: this.date_profile,
        note: this.note,
        link: this.link,
        link_image: this.link_image,
        unit_id: this.selUnit?.id,
        materials_id: this.selMaterial?.id,
      };
      if (this.status == 0){
        params.status = 0;
      }
      else params.status = this.status?.value;
      this.$emit("save", params);
    },
    async getData(id) {
      var { data } = await this.$apollo.query({
        query: DataServices.getList("product", {
          fields:
            "id name code status  material{id name code} unit{id name} company{id name code}  origin{id name} link link_image note barcode barcode_bucket code_profile_public code_profile date_profile fragile sub_label sub_stamp anti_counterfeiting_stamps temperature humidity warranty time_warranty info_book_time_book_optimal info_book_book_optimal info_book_storage_costs info_book_min_safe info_book_min_inventory cool_stamps defrosting_stamp import_stamp sub_lable ingredient regs_pack_height regs_pack_number_bucket_pallet regs_pack_number_pack_pallet regs_pack_number_product_pack packaging_height packaging_width packaging_length packaging_type storage_location_code producer expired_number infinite_date suppliers(where: {deleted: {_eq: false }}){id name number_time_book min_order_product ordering_costs}",
        }),
        variables: {
          where_key: {
            id: { _eq: id },
          },
        },
        fetchPolicy: "network-only",
      });
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
        this.code = this.models?.code;
        this.name = this.models?.name;
        this.selCompany = this.models?.company
          ? {
              id: this.models?.company?.id,
              name: this.models?.company?.name,
              code: this.models?.company?.code,
            }
          : null;
        this.expired_number = this.models?.expired_number;
        this.barcode = this.models?.barcode;
        this.barcode_bucket = this.models?.barcode_bucket;
        this.storage_location_code = this.models?.storage_location_code;
        this.regs_pack_height = this.models?.regs_pack_height;
        this.regs_pack_number_bucket_pallet =
          this.models?.regs_pack_number_bucket_pallet;
        this.regs_pack_number_pack_pallet =
          this.models?.regs_pack_number_pack_pallet;
        this.regs_pack_number_product_pack =
          this.models?.regs_pack_number_product_pack;
        this.packaging_height = this.models?.packaging_height;
        this.packaging_width = this.models?.packaging_width;
        this.packaging_length = this.models?.packaging_length;
        this.packaging_type = this.models?.packaging_type;
        this.producer = this.models?.producer;
        //console.log("this.models?.humidity:",this.models.humidity)
        //console.log("this.models?.humidity:",null)
        if (!this.$commonFuction.isEmpty(this.models.humidity)){
          //console.log("this.models?.sssssss:",this.models?.humidity)
          this.humidity = parseFloat(this.models?.humidity) ?? null;
        }
        this.temperature = this.models?.temperature;
        this.time_warranty = this.models?.time_warranty;
        this.selOrigin = this.models?.origin
          ? { id: this.models?.origin?.id, name: this.models?.origin?.name }
          : null;
        this.ingredient = this.models?.ingredient;
        this.sub_lable = this.models?.sub_lable;
        this.sub_stamp = this.models?.sub_stamp;
        this.anti_counterfeiting_stamps =
          this.models?.anti_counterfeiting_stamps;
        this.fragile = this.models?.fragile;
        this.defrosting_stamp = this.models?.defrosting_stamp;
        this.import_stamp = this.models?.import_stamp;
        this.cool_stamps = this.models?.cool_stamps;
        this.suppliers =
          this.models?.suppliers.map((e) => {
            return {
              name: e.name,
              number_time_book: e.number_time_book,
              min_order_product: e.min_order_product,
              ordering_costs: e.ordering_costs,
            };
          }) || [];
        this.status = this.listActive.filter(m => m.value == this.models?.status)[0] || null;
        this.infinite_date = this.models?.infinite_date;
        this.info_book_min_inventory = this.models?.info_book_min_inventory;
        this.info_book_min_safe = this.models?.info_book_min_safe;
        this.info_book_storage_costs = this.models?.info_book_storage_costs;
        this.info_book_book_optimal = this.models?.info_book_book_optimal;
        this.info_book_time_book_optimal =
          this.models?.info_book_time_book_optimal;
        this.code_profile_public = this.models?.code_profile_public;
        this.code_profile = this.models?.code_profile;
        this.date_profile = this.models?.date_profile ? new Date(this.models?.date_profile) : null;
        this.note = this.models?.note;
        this.link = this.models?.link;
        this.link_image = this.models?.link_image;
        this.selUnit = this.models?.unit
          ? { id: this.models?.unit?.id, name: this.models?.unit?.name }
          : null;
        this.selMaterial = this.models?.material
          ? {
              id: this.models?.material?.id,
              name: this.models?.material?.name,
              code: this.models?.material?.code,
            }
          : null;
        this.componentKey++;
      }
    },
  },
  apollo: {},
};
</script>
<style lang="scss">
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only {
      padding: 0 !important;
    }
  }
}
.p-panel {
  .p-panel-header {
    background: unset;
    border: none;
    border-bottom: 1px solid #c8c8c8;
  }
  .p-panel-content {
    background: #fafafa;
  }
}

input[disabled="disabled"] {
  background: #d0d4d9;
}

.view-action {
  .toolbar-top {
    background: #fff;
    padding: 0;
    border: 0 none;
    margin-bottom: 16px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eeeeee;
    .manager-title {
      border: 0 none;
      padding: 0;
      margin: 0;
    }
    .p-toolbar-group-right {
      .p-button {
        margin-right: 8px;
        width: auto;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .p-tabview {
    .p-tabview-panels {
      padding: 0;
    }
    .p-tabview-nav {
      li {
        margin-right: 8px;
        .p-tabview-nav-link {
          border: 1px solid #b4b4b4;
          border-radius: 5px 5px 0px 0px !important;
          background: #eee;
          font-size: 15px;
          &:hover {
            text-decoration: none;
          }
        }
        &.p-highlight {
          .p-tabview-nav-link {
            background: #00a8ff !important;
            border: 1px solid #b4b4b4;
            border-radius: 5px;
          }
        }
        &:hover {
          .p-tabview-nav-link {
            background: #00a8ff !important;
            color: #fff !important;
          }
        }
      }
    }
  }
  .p-formgrid {
    margin: 0;
    .p-panel-header {
      color: #000000;
      font-size: 18px;
      font-weight: bold;
      background: #eeeeee;
    }
    .p-card-field {
      padding: 24px;
      .p-field {
        .p-col-fixed {
          width: 400px;
          display: inline-block !important;
          margin: 0;
        }
        .p-inputtext p-component p-filled {
          width: 500px !important;
          border-radius: 5px;
          &.disabled {
            background: #eeeeee;
            border: 0 none;
            padding: 8px 10px;
            color: #707070;
            height: 40px;
          }
          &.rows-4 {
            height: 80px;
          }
          .file-select {
            margin: 0;
            input[type="file"] {
              display: none;
            }
            .select-button {
              display: inline-block;
              cursor: pointer;
              background: transparent;
              border-radius: 5px;
              color: #000000;
              font-size: 13px;
              padding: 3px 16px;
              border: 1px solid #b4b4b4;
            }
          }
        }
        .box-images {
          margin-top: 10px;
          position: relative;
          .item-img {
            height: 150px;
            margin-right: 8px;
            margin-bottom: 8px;
            display: inline-flex;
            position: relative;
            border: 0.5px solid #ccc;
            &.p-col-5 {
              width: 18%;
            }
            img {
              max-width: 100%;
              height: auto;
              margin: 0 auto;
              object-fit: contain;
            }
            .button-clear {
              width: 18px;
              height: 18px;
              background: #f5564e;
              float: right;
              cursor: pointer;
              position: absolute;
              right: 0px;
              img {
                vertical-align: top;
              }
            }
          }
        }
      }
    }
  }
  .p-rates {
    .top-rates {
      padding: 16px;
      color: #000;
      border-bottom: 1px solid #b4b4b4;
      label {
        margin: 0;
      }
      span {
        font-weight: 600;
        padding-left: 100px;
      }
    }
    .list-rates {
      padding: 16px;
    }
  }
  .table-view > .p-datatable .p-datatable-tbody > tr > td {
    color: #707070 !important;
  }
  .p-inputtext {
    min-height: 40px;
  }
  .p-formgrid .p-card-field .p-field .p-col-width {
    min-height: 40px;
    width: 100% !important;
  }
  .vs__dropdown-option.vs__dropdown-option--selected,
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background-color: #c40380 !important;
    color: #ffffff;
  }
  .span-cus {
    color: red;
  }
}
.product-form .p-panel-header {
  background-color: transparent !important;
}

// @media (max-width: 991px) {
.p-field.no-padding {
  padding: 0 !important;
  margin-bottom: 0 !important;
}

label.p-col-fixed.txt-left {
  padding-left: 0 !important;
}

.p-card-field.p-grid.pt-0 {
  padding-top: 0 !important;
}
.p-panel-content {
  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-bottom: 0px !important;
}
.p-col {
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
}
@media (max-width: 991px) {
  .p-card-field.p-grid {
    padding: 16px !important;
    .col-12.no-padding {
      padding: 0 !important;
      margin-bottom: 0 !important;
    }
  }
  .p-card-field.p-grid.responsive-no-pt {
    padding-top: 0 !important;
  }
  .p-card-field.p-grid.responsive-pb-2 {
    padding-bottom: 8px !important;
  }
}
@media (min-width: 992px) {
  .p-card-field.p-grid {
    padding: 16px !important;
    .col-12.no-padding {
      padding: 0 !important;
      margin-bottom: 0 !important;
    }
    .col-12.no-padding.responsive-padding-left {
      padding-left: 8px !important;
    }
  }
  .p-field.no-padding.p-lg-6.p-col-12,
  .p-field.col-lg-6.col-12.no-padding {
    padding-right: 16px !important;
  }
  .p-field.no-padding.p-lg-6.d-lg-block.d-none,
  .p-field.col-lg-6.col-12.no-padding.d-lg-block.d-none {
    padding-left: 16px !important;
  }
}

h5 {
  font-weight: bold;
}
.p-datatable.p-datatable-responsive {
    .p-datatable-tbody > tr > td {
      align-items: center;
      .responsive-label{
        margin-bottom: 0px !important;
      }
    }
    .p-datatable-tbody > tr > td {
      div {
        button:first-child{
          margin-right: 16px !important; 
        }
        button:last-child{
          margin-right: 0 !important;
        }
      }
    }
    .p-datatable-tbody > tr > td:last-child {
      margin-top: 8px;
      .responsive-label{
        margin-bottom: 0px !important;
      }
    }
    .p-datatable-tbody > tr > td:last-child {
      margin-top: 8px;
      .responsive-label{
        padding-top: 0px !important;
        margin-bottom: 0px !important;
      }
    }
}
.p-inputgroup-addon{
  height: 45px;
}
.vs__dropdown-toggle{
    background: white;
  }
</style>
