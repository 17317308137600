<template>
  <div class="p-fluid function-view">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="row manager-title-row">
          <div class="p-col-12 manager-title-custom pl-4">{{ title }}</div>
          <!-- <div class="p-col-8 txt-right">
            <Toolbar class="fixed-bottom-toolbar bg-transparent border-0">
              <template slot="right">
                <Button
                  label="Quay lại"
                  @click="backToList()"
                  class="p-button-warning callback-btn"
                />
                <Button
                  v-if="mode == 'edit' || this.$route.query.reexamine"
                  :label="'Lưu'"
                  @click="triggerSave = true"
                  class="p-button-success main-style-button"
                />
                <Button
                  v-else
                  :label="'Thêm mới'"
                  @click="triggerSave = true"
                  class="p-button-success main-style-button"
                />
              </template>
            </Toolbar>
          </div> -->
        </div>
        <!-- <div class="note">(*) Nội dung không được bỏ trống</div> -->
        <formEdit
          :key="componentKey"
          :mode="mode"
          @back="backToList"
          @save="saveData"
          :triggerSave.sync="triggerSave"
          :model="models"/>
      </div>
    </div>
  </div>
</template>

<script>
// import Config from '../../../config/index'
import DataServices from '@/core/DataServices'
import formEdit from './add'
// import ApiRepository from "@/core/ApiRepository";
import moment from "moment-timezone";
// import gql from "graphql-tag";
export default {
  components: {
    formEdit
  },
  data() {
    return {
      users: null,
      validationErrors: [],
      title: "Thêm Mới Sản Phẩm",
      mode: 'add',
      models: {},
      componentKey: 0,
      accountID: '',
      page_transaction:null,
      active_check:true,
      users_clubs_id : '',
      triggerSave: false,
      time_zone_local : moment.tz.guess(true),
      code: ''
    }
  },
  async mounted () {
    this.mode = this.$route.name === 'editProduct' ? 'edit' : this.mode;
    this.title = this.mode === 'edit' ? 'Chỉnh Sửa Sản Phẩm' : this.title;
    if (this.$route.params.id !== undefined) await this.getData(this.$route.params.id);
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
    //check quyền
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_PRODUCT);
    if (this.mode == 'edit'){
      if (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }else {
      if (arr_role.indexOf(this.$constants.ROLE.ADD) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }
  },
  methods: {
    backToList() {
      this.$router.push({ path: '/admin/list-product' });
    },
    
    async saveData(params) {
        this.$commonFuction.is_loading(true);
        var uid = this.$store.getters.user;
        console.log("Extracting status", params.status);
        let objects = {
            'code': params.code,
            'name': params.name,
            'company_id': params.company_id,
            'unit_id': params.unit_id,
            'materials_id': params.materials_id,
            'expired_number': params.expired_number,
            'barcode': params.barcode,
            'barcode_bucket': params.barcode_bucket,
            'storage_location_code': params.storage_location_code,
            'regs_pack_height': params.regs_pack_height,
            'regs_pack_number_bucket_pallet': params.regs_pack_number_bucket_pallet,
            'regs_pack_number_pack_pallet': params.regs_pack_number_pack_pallet,
            'regs_pack_number_product_pack': params.regs_pack_number_product_pack,
            'packaging_height': params.packaging_height,
            'packaging_width': params.packaging_width,
            'packaging_length': params.packaging_length,
            'packaging_type': params.packaging_type,
            'producer': params.producer,
            'humidity': params.humidity,
            'temperature': params.temperature,
            'time_warranty': params.time_warranty,
            'origin_id': params.origin_id,
            'ingredient': params.ingredient,
            'sub_lable': params.sub_lable,
            'sub_stamp': params.sub_stamp,
            'anti_counterfeiting_stamps': params.anti_counterfeiting_stamps,
            'fragile': params.fragile,
            'defrosting_stamp': params.defrosting_stamp,
            'import_stamp': params.import_stamp,
            'cool_stamps': params.cool_stamps,
            'status': params.status,
            'infinite_date': params.infinite_date,
            'info_book_min_inventory': params.info_book_min_inventory,
            'info_book_min_safe': params.info_book_min_safe,
            'info_book_storage_costs': params.info_book_storage_costs,
            'info_book_book_optimal': params.info_book_book_optimal,
            'info_book_time_book_optimal': params.info_book_time_book_optimal,
            'code_profile_public': params.code_profile_public,
            'code_profile': params.code_profile,
            'date_profile': params?.date_profile ? moment.tz(params.date_profile, this.time_zone_local).format("yyyy-MM-DD") : null,
            'note': params.note,
            'link': params.link,
            'link_image': params.link_image,
            'updated_at': moment(new Date()),
            'updated_by': uid,
            is_lack_information: false,
        }
        let suppliers = params.suppliers;
      if(this.mode == 'add'){
        objects['created_at'] = moment(new Date());
        objects['created_by'] = uid;
        await this.$apollo.mutate({
            mutation: DataServices.insertData('product'),
            variables: {
              objects: objects
            }
        }).then(async (res) => {
            if (res.data[Object.keys(res.data)].affected_rows) {
                var returning = res.data[Object.keys(res.data)].returning[0];
                for (let i=0; i < suppliers.length; i++) {
                    suppliers[i].product_id = returning.id;
                }
                let variables = {
                    objects: suppliers
                };
                await this.$apollo.mutate({
                    mutation: DataServices.insertData('supplies'),
                    variables: variables
                }).then(() => {
                    this.redirectEdit();
                }).catch(async error => {
                    console.log('error', error);
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                        detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                        life: 3000
                    });
                    this.$commonFuction.is_loading(false);
                });
            }
        })
      }else{
        let variables = {
          'data_update': objects,
          'where_key': {
            'id': {'_eq': this.models.id}
          }
        }
        await this.$apollo.mutate({
            mutation: DataServices.updateData('product'),
            variables: variables
        }).then(async (res) => {
            if (res.data[Object.keys(res.data)].affected_rows) {
                var returning = res.data[Object.keys(res.data)].returning[0];
                await this.$apollo.mutate({
                    mutation: DataServices.deleteData('supplies'),
                    variables: {
                        'where_key': {
                            'product_id': {'_eq': this.models.id}
                        }
                    }
                })
                for (let i=0; i < suppliers.length; i++) {
                  suppliers[i].product_id = returning.id;
                }
                let variables = {
                    objects: suppliers
                };
                await this.$apollo.mutate({
                    mutation: DataServices.insertData('supplies'),
                    variables: variables
                }).then(() => {
                    this.redirectEdit();
                }).catch(async error => {
                    console.log('error', error);
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                        detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                        life: 3000
                    });
                    this.$commonFuction.is_loading(false);
                });
            }
        })
      }
      this.$commonFuction.is_loading(false);
    },
    // async saveData(params) {
    //   // console.log("params",params)
    //   let objects = {
    //     'first_name' : params.first_name,
    //     'last_name' : params.last_name,
    //     'phone': params.phone,
    //     'email' : params.email,
    //     'full_birthday': params.full_birthday,
    //     'gender': params.gender,
    //     'date_booking': params.date_booking,
    //     'go_with': params.go_with,
    //     'uid': params.uid,
    //     'status': params.status,
    //     'patient_code': params.patient_code,
    //     'type_service_id': params.type_service_id,
    //     'branch_id': params.branch_id,
    //     'time_id': params.time_id,
    //     'prefix_phone': '+84'
    //   }
    //   let services = params.services;
    //   let questions = params.questions;
    //   this.triggerSave = false;
    //   if(this.mode == 'add'){
    //     console.log("ADD")
    //     await this.$apollo.mutate({
    //         mutation: DataServices.insertData('booking'),
    //         variables: {
    //           objects: objects
    //         }
    //     }).then(async (res) => {
    //         if (res.data[Object.keys(res.data)].affected_rows) {
    //             var returning = res.data[Object.keys(res.data)].returning[0];
    //             var servicesData = []
    //             for (let i=0; i < services.length; i++) {
    //                 servicesData.push({
    //                     'booking_id': returning.id,
    //                     'service_id': services[i].id,
    //                     'service_name': services[i].name
    //                 });
    //             }
    //             var questionsData = []
    //             for (let i=0; i < questions.length; i++) {
    //                 questionsData.push({
    //                     'booking_id': returning.id,
    //                     'question_id': questions[i].id,
    //                     'answer': questions[i].answer,
    //                     'question_title': questions[i].name
    //                 });
    //             }
    //             let variables = {
    //                 objects: servicesData
    //             };
    //             await this.$apollo.mutate({
    //                 mutation: DataServices.insertData('booking_line'),
    //                 variables: variables
    //             });
                
    //             let variables_question = {
    //                 objects: questionsData
    //             };
    //             await this.$apollo.mutate({
    //                 mutation: DataServices.insertData('booking_question'),
    //                 variables: variables_question
    //             }).then(() => {
    //                 this.redirectEdit();
    //             }).catch(async error => {
    //                 console.log('error', error);
    //                 this.$toast.add({
    //                     severity: 'error',
    //                     summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
    //                     detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
    //                     life: 3000
    //                 });
    //                 this.$commonFuction.is_loading(false);
    //             });
    //         }
    //     })
    //   }else{
    //     // var user_id = this.$route.params.id;
    //     let variables = {
    //       'data_update': objects,
    //       'where_key': {
    //         'id': {'_eq': this.models.id}
    //       }
    //     }
    //     await this.$apollo.mutate({
    //         mutation: DataServices.updateData('booking'),
    //         variables: variables
    //     }).then(async (res) => {
    //         if (res.data[Object.keys(res.data)].affected_rows) {
    //             var returning = res.data[Object.keys(res.data)].returning[0];
    //             await this.$apollo.mutate({
    //                 mutation: DataServices.deleteData('booking_line'),
    //                 variables: {
    //                     'where_key': {
    //                         'booking_id': {'_eq': this.models.id}
    //                     }
    //                 }
    //             })
                
    //             await this.$apollo.mutate({
    //                 mutation: DataServices.deleteData('booking_question'),
    //                 variables: {
    //                     'where_key': {
    //                         'booking_id': {'_eq': this.models.id}
    //                     }
    //                 }
    //             })
                
    //             var servicesData = []
    //             for (let i=0; i < services.length; i++) {
    //                 servicesData.push({
    //                     'booking_id': returning.id,
    //                     'service_id': services[i].id,
    //                     'service_name': services[i].name
    //                 });
    //             }
    //             var questionsData = []
    //             for (let i=0; i < questions.length; i++) {
    //                 questionsData.push({
    //                     'booking_id': returning.id,
    //                     'question_id': questions[i].id,
    //                     'answer': questions[i].answer,
    //                     'question_title': questions[i].name
    //                 });
    //             }
    //             let variables = {
    //                 objects: servicesData
    //             };
    //             await this.$apollo.mutate({
    //                 mutation: DataServices.insertData('booking_line'),
    //                 variables: variables
    //             });
                
    //             let variables_question = {
    //                 objects: questionsData
    //             };
    //             await this.$apollo.mutate({
    //                 mutation: DataServices.insertData('booking_question'),
    //                 variables: variables_question
    //             }).then(() => {
    //                 this.redirectEdit();
    //             }).catch(async error => {
    //                 console.log('error', error);
    //                 this.$toast.add({
    //                     severity: 'error',
    //                     summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
    //                     detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
    //                     life: 3000
    //                 });
    //                 this.$commonFuction.is_loading(false);
    //             });
    //         }
    //     })
    //   }
    //   this.$commonFuction.is_loading(false);
    // },
    async redirectEdit() {
      var that = this;
      var router_back = {path: '/admin/list-product'};
      that.$commonFuction.is_loading(false);
      if(that.mode == 'add'){
        that.$router.push(router_back);
      }else{
        if (!that.$commonFuction.isEmpty(router_back)){
          // that.page_transaction = ""+that.page_transaction
          router_back.query = { page_transaction: that.page_transaction.toString() };
        }
        that.$router.push(router_back);
      }
      that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Save data successfully', life: 3000 })
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('product', 
        {
            "fields" : "id name code active  material{id name} unit{id name} company{id name code}  origin{id name} link link_image note barcode barcode_bucket code_profile_public code_profile date_profile fragile sub_label sub_stamp anti_counterfeiting_stamps temperature humidity warranty time_warranty info_book_time_book_optimal info_book_book_optimal info_book_storage_costs info_book_min_safe info_book_min_inventory cool_stamps defrosting_stamp import_stamp sub_lable ingredient regs_pack_height regs_pack_number_bucket_pallet regs_pack_number_pack_pallet regs_pack_number_product_pack packaging_height packaging_width packaging_length packaging_type storage_location_code producer expired_number infinite_date suppliers(where: {deleted: {_eq: false }}){id name number_time_book min_order_product ordering_costs}"
        }),
        variables: {
          where_key: {
            id: {'_eq': id},
            deleted: {'_eq': false}
          }
        },
        fetchPolicy: "network-only"
      })
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
        if(this.models.active==false){
          this.active_check = false
        }
      }
      this.componentKey++;
    },
  },
  apollo: {
    
  }
}
</script>

<style lang="scss">
  .manager-title-custom{
    color: #1D3118;
    font-size: 24px;
    /* margin-bottom: 16px; */
    padding-bottom: 10px;
    /* border-bottom: 1px solid #EEEEEE; */
    font-weight: 700;
  }
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.note{ letter-spacing: 0px; color: #707070; margin-bottom: 24px; padding-top: 10px;}
.p-formgrid { margin: 0;
  .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
  .p-card-field{ padding: 24px;
    .p-field{
      .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
      .p-col-width{width:500px !important; border-radius: 5px;
        &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px;}
        .file-select{margin: 0;
          input[type="file"]{display: none;}
          .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
        }
        &.pac-target-input{ height: 80px;  word-break: break-all; overflow-wrap: break-word;  resize: none;}
      }
      .box-images{ margin: 0; margin-top: 10px; position: relative;
        .item-img{width: 150px; height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative;
          img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain; padding: 5px;}
          .button-clear{border-radius: 10px; width: 24px; height: 24px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
            img{vertical-align: top;}
          }
        }
      }
      .p-invalid{display: block;}
    }
  }
  .fixed-bottom-toolbar{background: transparent; border: 0 none; margin-top: 22px; border-top: 1px solid #B4B4B4; padding: 0; padding-top: 30px;
    .p-button{width: auto; margin-right: 8px;
      &:last-child{margin-right: 0;}
    }
  }
}
.manager-title-row{
  border-bottom: 1px solid #EEEEEE;
}
.function-view .p-toolbar-group-right {
  flex-basis: 100%;
  justify-content: flex-end;
}
.function-view .p-button-warning.p-button {
  margin: 0 10px;
}
</style>